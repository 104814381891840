import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  useNotify,
  useRefresh,
  useTranslate
} from 'react-admin';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';
import { API_URL } from '../../constants/constants';
import useSubscriber from './useSubscriber';

const SubscriptionField = ({ record, type }) => {
  const typeToField = {
    email: 'unsubscribeEmail',
    phone: 'unsubscribePhone'
  };

  return record[typeToField[type]] === null ? <div>V</div> : <div>X</div>;
};

export const SubscribersList = (props) => (
  <List
    {...props}
    filters={<SubscribersFilter />}
    sort={{ field: 'email', order: 'ASC' }}
    bulkActionButtons={false}
    perPage={25}
    filter={{
      specificFields: [
        'sid',
        'uid',
        'email',
        'phone',
        'createdAt',
        'unsubscribeEmail',
        'unsubscribePhone',
        'whatsappRecommendations',
        'nodeId'
      ]
    }}
  >
    <Datagrid>
      <TextField source="sid" />
      <TextField source="uid" />
      <TextField source="email" />
      <TextField source="phone" />
      <DateField source="createdAt" />
      <SubscriptionField type="email" label="subscribe email" />
      <SubscriptionField type="phone" label="subscribe phone" />

      <EditButton />
    </Datagrid>
  </List>
);

const SubscribersFilter = (props) => (
  <Filter {...props}>
    <TextInput source="uid" />
    <TextInput source="email" />
    <TextInput source="phone" />
  </Filter>
);

export const SubscribersEdit = (props) => {
  const { subscriber: record } = useSubscriber(props.id);
  const [emailChecked, setEmailChecked] = useState(true);
  const [phoneChecked, setPhoneChecked] = useState(true);
  const [whatsappChecked, setWhatsappChecked] = useState(true);
  const [loading, setIsLoading] = useState();
  const { token } = useFirebaseToken();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      await graphqlServiceRequest(
        token,
        `mutation {
          ${
            phoneChecked
              ? 'subscriberSubscribePhone'
              : 'subscriberUnsubscribePhone'
          }(input: { uid: "${record.uid}" }) {
            clientMutationId
          }

          ${
            whatsappChecked
              ? 'subscribeToWhatsappRecommendation'
              : 'unsubscribeToWhatsappRecommendation'
          }(input: { uid: "${record.uid}" }) {
            clientMutationId
          }

          ${
            emailChecked
              ? 'subscriberSubscribeEmail'
              : 'subscriberUnsubscribeEmail'
          }(input: { uid: "${record.uid}" }) {
            clientMutationId
          }
        }`,
        API_URL
      );

      setIsLoading(false);
      notify(translate('ra.notification.updated'));
      refresh();
      // eslint-disable-next-line no-shadow
    } catch (e) {
      setIsLoading(false);
      notify(translate('ra.page.error'), 'warning');
    }
  };

  useEffect(() => {
    if (record) {
      // console.log('record', record);
      setEmailChecked(record.unsubscribeEmail === null);
      setPhoneChecked(record.unsubscribePhone === null);
      // setWhatsappChecked(record.whatsappRecommendations === true);
    }
  }, [record]);

  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '500px',
        padding: 16
      }}
    >
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={emailChecked}
                onChange={(e) => setEmailChecked(e.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="subscribe email"
          />
          <FormControlLabel
            control={
              <Switch
                checked={phoneChecked}
                onChange={(e) => setPhoneChecked(e.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="subscribe phone"
          />
        </FormGroup>
        <Button type="submit" variant="contained" color="primary">
          {loading ? 'loading...' : 'Save'}
        </Button>
      </form>
    </Paper>
  );
};
