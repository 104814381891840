import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useLatestDynamicContent = () => {
  const [dynamicContent, setDynamicContent] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchDynamicContent = async () => {
    const filters = {
      specificFields: [
        'id',
        'sourceTable',
        'campaignName',
        'branches',
        'creatingUid',
        'desktopImageUrl',
        'mobileImageUrl',
        'startDate',
        'endDate',
        'header',
        'headerColor',
        'headerSize',
        'headerWeight',
        'location',
        'status',
        'subHeader',
        'subHeaderColor',
        'subHeaderSize',
        'subHeaderWeight',
        'text',
        'timer',
        'isLink',
        'updatedAt',
        'url',
        'color',
        'iconUrl',
        'isActive'
      ]
    };

    const { data } = await dataProvider(GET_LIST, 'LatestDynamicContentView', {
      filter: filters,
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedContent = pipe(
      map((content) => ({
        id: prop('originalId', content),
        nodeId: prop('id', content),
        sourceTable: prop('sourceTable', content),
        campaignName: prop('campaignName', content),
        startDate: prop('startDate', content),
        endDate: prop('endDate', content),
        status: prop('status', content),
        creatingUid: prop('creatingUid', content),
        location: prop('location', content),
        header: prop('header', content),
        headerColor: prop('headerColor', content),
        headerSize: prop('headerSize', content),
        headerWeight: prop('headerWeight', content),
        subHeader: prop('subHeader', content),
        subHeaderColor: prop('subHeaderColor', content),
        subHeaderSize: prop('subHeaderSize', content),
        subHeaderWeight: prop('subHeaderWeight', content),
        desktopImageUrl: prop('desktopImageUrl', content),
        mobileImageUrl: prop('mobileImageUrl', content),
        timer: prop('timer', content),
        isLink: prop('isLink', content),
        url: prop('url', content),
        branches: prop('branches', content),
        text: prop('text', content),
        color: prop('color', content),
        iconUrl: prop('iconUrl', content),
        isActive: prop('isActive', content)
      })),
      filter(prop('id'))
    )(data);

    setDynamicContent([...mappedContent]);
  };

  useEffect(() => {
    fetchDynamicContent();
  }, [version]);

  return {
    dynamicContent
  };
};

export default useLatestDynamicContent;
