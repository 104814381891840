const currentYear = new Date().getFullYear();
export const YEARS = [
  currentYear,
  currentYear - 1,
  currentYear - 2,
  `${currentYear - 3} - ${currentYear - 6}`,
  `${currentYear - 7} - ${currentYear - 14}`
];

export const interestPlanInfo = {
  1: { title: 'שנתון נוכחי', yearRange: YEARS[0] },
  2: { title: 'גיל רכב שנה קודמת', yearRange: YEARS[1] },
  3: { title: 'גיל רכב שנתיים קודמות', yearRange: YEARS[2] },
  4: { title: 'גיל רכב  3-6', yearRange: YEARS[3] },
  5: { title: ' גיל רכב 7-14', yearRange: YEARS[4] }
};
