import firebase from 'firebase';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  usePermissions
} from 'react-admin';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';

import SpecificFieldsEdit from '../../components/SpecificFieldsEdit';
import { API_URL } from '../../constants/constants';
import { currentDate } from '../../utils/utils';
import { EditToolbar } from './EditToolbar';

const rateValidator = (value) => {
  if (value.toString().match('^([1-9]\\d{2,10})')) {
    return 'הערך צריך להיות פחות מ100%';
  }
  if (value.toString().match('^(0|[1-9]\\d{0,1})(\\.\\d{3,10})')) {
    return ' ריבית צריכה להיות עד 2 ספרות אחרי הנקודה';
  }
  return undefined;
};

const validateRate = [required(), rateValidator];
// eslint-disable-next-line import/prefer-default-export
export const FundingCalcVariableUpdateEditList = (props) => {
  const { permissions, loaded: permissionsLoaded } = usePermissions();
  const [userName, setUserName] = useState('');
  const [userUid, setUserUid] = useState('');

  useEffect(() => {
    if (permissionsLoaded && permissions) {
      setUserUid(permissions.uid);
      setUserName(permissions.name);
    }
  }, [permissionsLoaded, permissions]);

  // useEffect(() => {
  //   (async () => {
  //     const user = firebase.auth().currentUser;
  //     const token = await user.getIdToken();
  //     const profile = await graphqlServiceRequest(
  //       token,
  //       `{
  //       profile {
  //         name
  //         uid
  //       }
  //     }`,
  //       API_URL
  //     ).then((value) => value.data.profile);
  //     setUserName(profile.name);
  //     setUserUid(profile.uid);
  //   })();
  // }, []);

  return (
    <SpecificFieldsEdit
      {...props}
      specificFields={['nodeId', 'id', 'rate', 'vehicleAge']}
    >
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="vehicleAge" label="מסלול" disabled />
        <NumberInput
          source="rate"
          label="ריבית"
          validate={validateRate}
          helperText="יש להזין אחוזים כמספרים שלמים, כלומר X.XX"
        />

        <TextInput
          style={{ display: 'none' }}
          source="updatingUserUid"
          label="uid"
          defaultValue={userUid}
          disabled
        />
        <TextInput
          source="user.userName"
          label="שם משתמש"
          disabled
          defaultValue={userName}
        />

        <TextInput
          source="updatedAt"
          label="תאריך ושעת עדכון"
          defaultValue={currentDate}
          disabled
        />
      </SimpleForm>
    </SpecificFieldsEdit>
  );
};
