/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { useField } from 'react-final-form';
import { dropLast, prop, filter, path, trim } from 'ramda';
import { FormControl, InputLabel, Input } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  autocompleteInput: {
    width: '250px',
    height: '40px',
    marginTop: '5px',
    marginBottom: '15px',
    backgroundColor: '#E8E8E8',
    border: '1px solid #E8E8E8',
    borderRadius: '5px 5px 0px 0px',
    fontSize: '20px',
    padding: '16px 0 7px'
  },
  addressLabel: {
    zIndex: '99999',
    marginTop: '7px',
    marginLeft: '5px',
    fontSize: '12px'
  },
  formControl: {
    width: '100%'
  }
}));

const activeOption = { backgroundColor: '#fafafa', cursor: 'pointer' };
const notActiveOption = { backgroundColor: '#ffffff', cursor: 'pointer' };

const searchOptions = {
  componentRestrictions: {
    country: 'IL'
  }
};

// eslint-disable-next-line camelcase
const extract_address_component = (geoLocation, componentName) => {
  // eslint-disable-next-line camelcase
  const address_components = prop('address_components', geoLocation); // administrative_area_level_4
  const filtered = filter(
    (component) => path(['types', 0], component) === componentName,
    address_components
  )[0];
  const filteredFallback = filter(
    (component) =>
      path(['types', 0], component) === 'administrative_area_level_4',
    address_components
  )[0];

  return filtered || filteredFallback || '';
};

const parseAddress = (addressStr) => {
  const streetSplitted = addressStr.formatted_address
    .replace(/"/g, '\\"')
    .split(',');
  const streetWithoutCounty = dropLast(1, streetSplitted);
  return {
    address: streetWithoutCounty.join(','),
    street: streetWithoutCounty.slice(0, -1).join(',')
  };
};

const AutocompletePlace = ({
  label = 'כתובת',
  onAfterSelect = () => {},
  isMoked = false,
  ...props
}) => {
  const classes = useStyles();
  const [address, setAddress] = useState();
  const {
    input: { onChange: changeLongitude }
  } = useField('longitude');
  const {
    input: { onChange: changeLatitude }
  } = useField('latitude');
  const {
    input: { onChange: changeCity }
  } = useField('city');
  const {
    input: { onChange: changeStreet }
  } = useField('street');
  const {
    input: { onChange: changeAddress }
  } = useField('address');
  const {
    input: { onChange: changeDistrict }
  } = useField('district');

  // eslint-disable-next-line no-shadow
  const handleChange = (address) => {
    setAddress(address);
  };

  // eslint-disable-next-line no-shadow
  const handleSelect = async (address) => {
    try {
      const geocodedAddresses = await geocodeByAddress(address);
      const relevantGeocodedAddress = geocodedAddresses[0];
      const parsedAddress = parseAddress(relevantGeocodedAddress);
      const { lat, lng } = await getLatLng(relevantGeocodedAddress);
      const districtComponent = extract_address_component(
        relevantGeocodedAddress,
        'administrative_area_level_1'
      );
      const cityComponent = extract_address_component(
        relevantGeocodedAddress,
        'locality'
      );
      const city =
        cityComponent && cityComponent.long_name
          ? trim(prop('long_name', cityComponent))
          : '';
      changeLatitude(lat);
      changeLongitude(lng);
      changeCity(city);
      changeStreet(trim(parsedAddress.street));
      changeAddress(trim(parsedAddress.address));
      let districtPresent = false;
      if (districtComponent) {
        changeDistrict(trim(prop('long_name', districtComponent)));
        districtPresent = true;
      }
      onAfterSelect(city, districtPresent, changeCity, changeDistrict);

      setAddress(trim(parsedAddress.address));
    } catch (e) {
      setAddress('');
      alert(e);
    }
  };

  const placeHolder = isMoked ? 'הזן כתובת' : 'הזן כתובת * ';

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="autocomplete-input" className={classes.addressLabel}>
        {label}
      </InputLabel>
      <PlacesAutocomplete
        value={address || props.record?.address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        id="autocomplete-input"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: placeHolder,
                className: classes.autocompleteInput
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? activeOption
                  : notActiveOption;
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </FormControl>
  );
};

export default AutocompletePlace;
