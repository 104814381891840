import phone from 'phone';
import * as PropTypes from 'prop-types';
import { keys, prop } from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  EditButton,
  email,
  Filter,
  FormDataConsumer,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useTranslate,
  BooleanField,
  FunctionField
} from 'react-admin';

import SpecificFieldsEdit from '../../components/SpecificFieldsEdit';
import useAgencyBranchesForAutoComplete from '../../utils/hooks/useAgencyBranchesForAutoComplete';
import useProPermissions from '../../utils/hooks/useProPermissions';
import useTeamsForAutoComplete from '../../utils/hooks/useTeamsForAutoComplete';
import useUserCitiesForAutoComplete from '../../utils/hooks/useUserCitiesForAutoComplete';
import { nameValidator } from '../../utils/resourceCommonUtils.js/validatorUtils';
import CreateUserButton from './CreateUserButton';
import UpdateUserButton from './UpdateUserButton';
import DeleteUserButton from './DeleteUserButton';

const formatPhone = (value) => {
  if (value.length > 9) {
    return prop('phoneNumber', phone(value, { country: 'IL' }));
  }
  return value;
};
const onSuccess = (data) => {
  data.phone = formatPhone(data.phone);
  data.name = data.name.trim();
};

function Field(props) {
  return null;
}

Field.propTypes = {
  onBlur: PropTypes.func,
  component: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string
};

const phoneValidator = (value) => {
  const phoneReg = /^05\d{8}$/;
  if (!value.match(phoneReg) && value.length < 5) {
    return 'phone number should start with 05';
  }
  if (value.length > 5 && value.length < 10) {
    return 'phone number is too short';
  }
  if (value.length > 10) {
    return 'phone number is too long';
  }

  return undefined;
};

// const nameValidator = (value) => {
//   const nameReg = /^[a-zA-Zא-ת]+$/;
//   const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
//   if (value.match(specialChars) && !value.match(nameReg)) {
//     return 'name should contain only letters';
//   }

//   return undefined;
// };

const validateEmail = [email('email isnt valid')];
const validatePhone = [required(), phoneValidator];
const validateName = [required(), nameValidator];

export const UsersList = (props) => {
  const { agencyBranches } = useAgencyBranchesForAutoComplete();

  return (
    <List
      {...props}
      filters={<UsersFilter />}
      sort={{ field: 'createdAt', order: 'ASC' }}
      bulkActionButtons={false}
      perPage={25}
      filter={{
        specificFields: [
          'nodeId',
          'uid',
          'name',
          'phone',
          'email',
          'createdAt',
          'updatedAt',
          'role',
          'teamId',
          'agencyBranchId',
          'secondaryRoleId'
        ]
      }}
    >
      <Datagrid>
        <TextField source="uid" />
        <TextField source="name" />
        <TextField source="phone" />
        <TextField source="email" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <TextField source="role" />
        <ReferenceField
          source="secondaryRoleId"
          reference="ProPermission"
          link={false}
          label="secondary Role"
        >
          <TextField label="secondary Role" source="secondaryRole" />
        </ReferenceField>
        <ReferenceField
          source="teamId"
          reference="TeamsView"
          link={false}
          label="צוות"
        >
          <TextField label="צוות" source="name" />
        </ReferenceField>
        <FunctionField
          label="סניף"
          render={(r) => {
            const branch = (agencyBranches || []).find(
              (a) => a.id === r.agencyBranchId
            );

            return branch ? branch.name : '';
          }}
        />

        <EditButton />
      </Datagrid>
    </List>
  );
};

const UsersFilter = (props) => {
  const { teams } = useTeamsForAutoComplete();
  const { agencyBranches } = useAgencyBranchesForAutoComplete();

  return (
    <Filter {...props}>
      <TextInput source="phone" />
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="uid" />
      <SelectInput
        source="role"
        choices={[
          { id: 'ADMIN', name: 'ADMIN' },
          { id: 'BUSINESS_MANAGER', name: 'BUSINESS_MANAGER' },
          { id: 'BUSINESS_LOCAL', name: 'BUSINESS_LOCAL' },
          { id: 'CLIENT', name: 'CLIENT' },
          { id: 'CARWIZ_AGENT', name: 'CARWIZ_AGENT' }
        ]}
      />
      <AutocompleteInput
        source="agencyBranchId"
        choices={agencyBranches}
        // reference="AgencyBranch"
        allowEmpty
        // perPage={2000}
      />

      <ReferenceInput
        source="secondaryRoleId"
        reference="ProPermission"
        allowEmpty
        perPage={2000}
      >
        <SelectInput optionText="secondaryRole" optionValue="originalId" />
      </ReferenceInput>

      <SelectInput choices={teams} allowEmpty source="teamId" />
    </Filter>
  );
};

export const UsersEdit = ({ ...props }) => {
  const translate = useTranslate();
  const { agencyBranches } = useAgencyBranchesForAutoComplete();
  const { userCities } = useUserCitiesForAutoComplete();
  const { teams } = useTeamsForAutoComplete();
  const { proPermissions } = useProPermissions();
  const [selectedRole, setSelectedRole] = useState();
  const [teamsDisabled, setTeamsDisabled] = useState();
  const [secondaryRoleDisabled, setSecondaryRoleDisabled] = useState();

  useEffect(() => {
    setTeamsDisabled(
      selectedRole
        ? selectedRole !== 'ADMIN' && selectedRole !== 'CARWIZ_AGENT'
        : null
    );

    setSecondaryRoleDisabled(
      selectedRole ? selectedRole !== 'CARWIZ_AGENT' : null
    );
  }, [selectedRole]);

  return (
    <SpecificFieldsEdit
      {...props}
      specificFields={[
        'nodeId',
        'id',
        'uid',
        'name',
        'phone',
        'email',
        'createdAt',
        'updatedAt',
        'role',
        'teamId',
        'agencyBranchId',
        'virtualPhone',
        'secondaryRoleId',
        'cityCode'
      ]}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <UpdateUserButton />
            {/* <DeleteUserButton record={props.record} /> */}
          </Toolbar>
        }
      >
        <TextInput source="uid" disabled />
        <TextInput source="name" validate={validateName} />

        <TextInput source="phone" disabled />
        <TextInput source="virtualPhone" />

        <TextInput source="email" />

        <AutocompleteInput
          choices={userCities}
          source="cityCode"
          label="עיר"
          allowEmpty
        />

        <TextInput disabled source="createdAt" />
        <TextInput disabled source="updatedAt" />
        <SelectInput
          source="role"
          validate={required()}
          onChange={(e) => setSelectedRole(e.target.value)}
          choices={[
            { id: 'ADMIN', name: 'ADMIN' },
            { id: 'BUSINESS_MANAGER', name: 'BUSINESS_MANAGER' },
            { id: 'BUSINESS_LOCAL', name: 'BUSINESS_LOCAL' },
            { id: 'CLIENT', name: 'CLIENT' },
            { id: 'CARWIZ_AGENT', name: 'CARWIZ_AGENT' }
          ]}
        />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) => {
            const isAgentInitalValue = formData.role === 'CARWIZ_AGENT';
            return (
              <AutocompleteInput
                choices={proPermissions}
                source="secondaryRoleId"
                disabled={
                  secondaryRoleDisabled !== null
                    ? secondaryRoleDisabled
                    : !isAgentInitalValue
                } // only admin/agent can be in a team
                allowEmpty
                label={translate('ra.resources.users.secondary_role')}
                format={(value) => (secondaryRoleDisabled ? null : value)}
                parse={(value) => (secondaryRoleDisabled ? null : value)}
              />
            );
          }}
        </FormDataConsumer>
        <AutocompleteInput
          choices={agencyBranches}
          source="agencyBranchId"
          label="סניפים"
        />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) => {
            const isAdminOrAgentInitalValue =
              formData.role === 'ADMIN' || formData.role === 'CARWIZ_AGENT';
            return (
              <AutocompleteInput
                choices={teams}
                source="teamId"
                disabled={
                  teamsDisabled !== null
                    ? teamsDisabled
                    : !isAdminOrAgentInitalValue
                } // only admin/agent can be in a team
                allowEmpty
                label={translate('ra.resources.teams.team')}
                format={(value) => (teamsDisabled ? null : value)}
                parse={(value) => (teamsDisabled ? null : value)}
              />
            );
          }}
        </FormDataConsumer>

        <ReferenceManyField
          reference="Subscriber"
          target="uid"
          source="uid"
          label="subscriber"
          sort={{ field: 'uid', order: 'DESC' }}
          filter={{
            specificFields: [
              'sid',
              'unsubscribeEmail',
              'unsubscribePhone',
              'whatsappRecommendations',
              'nodeId'
            ]
          }}
        >
          <Datagrid>
            <TextField source="unsubscribeEmail" />
            <TextField source="unsubscribePhone" />

            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        {/* <UserProps /> */}
      </SimpleForm>
    </SpecificFieldsEdit>
  );
};

export const UserCreate = (props) => {
  const translate = useTranslate();
  const { userCities } = useUserCitiesForAutoComplete();
  const { agencyBranches } = useAgencyBranchesForAutoComplete();
  const { teams } = useTeamsForAutoComplete();
  const { proPermissions } = useProPermissions();
  const [selectedRole, setSelectedRole] = useState();

  return (
    <Create mutationOptions={{ onSuccess }} {...props}>
      <SimpleForm
        toolbar={
          <Toolbar>
            <CreateUserButton />
          </Toolbar>
        }
      >
        <TextInput source="name" validate={validateName} />
        <TextInput
          id="phone"
          label="phone"
          placeholder="please type phone number that starts with 05"
          source="phone"
          validate={validatePhone}
          initialValue="05"
        />

        <TextInput source="email" validate={validateEmail} />

        <AutocompleteInput
          choices={userCities}
          source="cityCode"
          label="עיר"
          allowEmpty
        />
        <TextInput disabled source="createdAt" />
        <TextInput disabled source="updatedAt" />
        <SelectInput
          source="role"
          validate={required()}
          onChange={(e) => setSelectedRole(e.target.value)}
          choices={[
            { id: 'ADMIN', name: 'ADMIN' },
            { id: 'BUSINESS_MANAGER', name: 'BUSINESS_MANAGER' },
            { id: 'BUSINESS_LOCAL', name: 'BUSINESS_LOCAL' },
            { id: 'CLIENT', name: 'CLIENT' },
            { id: 'CARWIZ_AGENT', name: 'CARWIZ_AGENT' }
          ]}
        />
        <SelectInput
          choices={proPermissions}
          disabled={selectedRole !== 'CARWIZ_AGENT'} // only agent can have secondary role
          source="secondaryRoleId"
          label={translate('ra.resources.users.secondary_role')}
          allowEmpty
        />
        <AutocompleteInput
          choices={agencyBranches}
          source="agencyBranchId"
          label="סניפים"
        />
        <AutocompleteInput
          choices={teams}
          disabled={selectedRole !== 'ADMIN' && selectedRole !== 'CARWIZ_AGENT'} // only admin/agent can be in a team
          source="teamId"
          label={translate('ra.resources.teams.team')}
          allowEmpty
        />
      </SimpleForm>
    </Create>
  );
};
